import { useContext, useState } from "react";
import { CartContext } from "../../../contexts/ShoppingCartContext";
import { moneyFormat } from "../../../utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ScrollingText } from "../scrolling-text/ScrollingText";
import LogoToubelle from "../../../assets/images/branding/logo-toubelle.png";
import LogoWompi from "../../../assets/images/branding/wompi-logo-principal.png";
import "./Header.css";

export const Header = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const {
    total,
    countProducts,
    setSearch,
    setShipmentData,
    setDataRecord,
    setDocument,
  } = useContext(CartContext);

  /**
   * Maneja el cambio en el campo de búsqueda.
   * @param {Object} event - El evento del cambio en el campo de búsqueda.
   * @param {Object} event.target - El elemento que disparó el evento.
   * @param {string} event.target.value - El valor actual del campo de búsqueda.
   * @param {string} event.key - La tecla presionada durante el evento.

   */
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setSearch(event.target.value); 

    if (event.key === "Enter") {
      handleButtonClick(); 
    };
  };

  /**
   * Maneja el evento de clic en el botón. Navega a la página de inicio y establece el estado para desplazarse a la sección de productos.
   */
  const handleButtonClick = () => {
    navigate("/", { state: { scrollToProductos: true } });
  };

  const location = useLocation();

  /**
   * Desactiva el estado de varios elementos del componente.
   * Esta función restablece los siguientes estados a sus valores iniciales:
   * - `shipmentData` se establece en `null`.
   * - `dataRecord` se establece en `null`.
   * - `document` se establece en una cadena vacía.
   */
  const deactivate = () => {
    setShipmentData(null);
    setDataRecord(null);
    setDocument("");
  };

  return (
    <header>
      <div className="navbar">
        <ScrollingText></ScrollingText>
        <div className="navbar-main">
          <div className="logo-header">
            <a href="/" onClick={() => deactivate()}>
              <img src={LogoToubelle} alt="Logo de Toubelle."></img>
            </a>
          </div>
          <div className="navbar-items">
            {location.pathname !== "/carrito" &&
              location.pathname !== "/politica" && (
                <div className="navbar-item">
                  <div className="search-box">
                    <input
                      className="search-input"
                      type="search"
                      placeholder="Busca tu producto..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyDown={handleSearchChange}
                    />
                    <button
                      className="search-button"
                      type="button"
                      onClick={handleButtonClick}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                  <div className="cart-info">
                    <div className="cart-input">
                      <Link
                        to="/carrito"
                        className="cart-button"
                        type="button"
                      >
                        {countProducts === 0 ? (
                          ""
                        ) : (
                          <span className="count-cart">{countProducts}</span>
                        )}
                        <i className="fa-solid fa-cart-shopping" />
                      </Link>
                    </div>
                    <div className="cart-text">
                      <span className="cart-title">Tu carrito</span>
                      <span className="cart-price">
                        {moneyFormat(total)} COP
                      </span>
                    </div>
                  </div>
                </div>
              )}

            {location.pathname === "/carrito" && (
              <div className="navbar-item">
                <div className="navbar-title">
                  <p>
                    Tu compra es <strong>100%</strong> <span>Segura</span> y{" "}
                    <span>Confiable.</span>
                  </p>
                </div>
                <div className="navbar-logo">
                  <img src={LogoWompi} alt="" />
                </div>
              </div>
            )}

            {location.pathname === "/politica" && (
              <div className="navbar-item">
                <div className="navbar-title">
                  <p>
                    TouBelle es <strong>100%</strong> <span>Segura</span> y{" "}
                    <span>Confiable.</span>
                  </p>
                </div>
                <div className="navbar-logo">
                  <img src={LogoWompi} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="header-mb">
        <div className="navbar-item">
          {location.pathname !== "/carrito" &&
            location.pathname !== "/politica" && (
              <div className="search-box">
                <input
                  className="search-input"
                  type="search"
                  placeholder="Busca tu producto..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchChange}
                />
                <button
                  className="search-button"
                  type="button"
                  onClick={handleButtonClick}
                >
                  <i className="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            )}
          {location.pathname === "/carrito" && (
            <div className="navbar-title">
              <p>
                Tu compra es <strong>100%</strong> <span>Segura</span> y{" "}
                <span>Confiable.</span>
              </p>
            </div>
          )}

          {location.pathname === "/politica" && (
            <div className="navbar-title">
              <p>
                TouBelle es <strong>100%</strong> <span>Segura</span> y{" "}
                <span>Confiable.</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
