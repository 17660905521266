/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./BannerList.css";
import BannerImg00 from "../../assets/images/branding/banner-toubelle-01.jpg";

export const BannerList = () => {
  const images = [BannerImg00];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="slider-container">
        <div className="slider">
          {images.map((image, index) => (
            <div className="slide" key={index}>
              <img src={image} alt={`Imagen ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
