/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState, useRef } from "react";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { useLocation } from "react-router-dom";
import { moneyFormat } from "../../utils";
import IconProm from "../../assets/images/branding/Promo-Icon.png";
import "./ProductList.css";

export const ProductList = () => {
  const {
    allProducts,
    setAllProducts,
    setTotal,
    setCountProducts,
    search,
    setSearch,
    filterTag,
    setFilterTag,
    productList,
  } = useContext(CartContext);
  const [searchResults, setSearchResults] = useState(productList);
  const [categoryResults, setCategoryResults] = useState(productList);
  const location = useLocation();
  const productosRef = useRef(null);

  useEffect(() => {
    setFilterTag("");
    setSearch("");
  }, [setFilterTag, setSearch]);

  useEffect(() => {
    if (location.state?.scrollToProductos && productosRef.current) {
      productosRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    if (search && typeof search === "string") {
      setFilterTag("");
      const searchValue = search.toLowerCase();
      const filteredProducts = Array.isArray(productList)
        ? productList.filter((product) =>
            product.characteristics.toLowerCase().includes(searchValue)
          )
        : [];
      setSearchResults(filteredProducts);
    } else {
      setSearchResults(productList);
    }
  }, [search, productList, setFilterTag]);

  useEffect(() => {
    if (filterTag) {
      setSearch("");
      console.log(filterTag);
      const filteredByCategory = Array.isArray(productList)
        ? productList.filter((product) => product.categoryId === filterTag.id)
        : [];
      setCategoryResults(filteredByCategory);
    } else {
      setCategoryResults(searchResults);
    }
  }, [filterTag, productList, searchResults, setSearch]);

  /**
   * Añade un producto a la lista de productos.
   * @param {Object} product - El producto que se va a añadir.
   * @param {number} product.id - El ID del producto.
   * @param {number} product.price - El precio del producto.
   * @param {number} [product.quantity] - La cantidad del producto (opcional).
   * @returns {boolean} - Retorna true si el producto fue añadido exitosamente.
   * @example
   * const product = { id: 1, price: 1000, quantity: 1 };
   * const isAdded = onAddProducts(product);
   * console.log(isAdded);
   * // true
   */
  const onAddProducts = (product) => {
    const inputValue = 1;
    const existingProduct = allProducts.find((item) => item.id === product.id);
    if (existingProduct) {
      const updatedProducts = allProducts.map((item) =>
        item.id === product.id
          ? { ...item, quantity: item.quantity + inputValue }
          : item
      );
      setAllProducts(updatedProducts);
    } else {
      const newProduct = { ...product, quantity: inputValue };
      setAllProducts([...allProducts, newProduct]);
    }
    setTotal((prevTotal) => prevTotal + inputValue * product.price);
    setCountProducts((prevCount) => prevCount + inputValue);
    return true;
  };

  /**
   * Generates a message based on the state of product lists, categories, and search filters.
   * @returns {string} A message indicating the status of product availability, search results, or category filters.
   * @example
   * const message = getMessage();
   * console.log(message);
   * // "Descubre los productos TouBelle y lo que hacen por tu piel"
   */
  const getMessage = () => {
    if (Array.isArray(productList) && productList.length === 0) {
      return `No hay productos TouBelle disponibles`;
    }

    if (Array.isArray(categoryResults) && categoryResults.length === 0 && filterTag ) {
      return `No se encontraron productos en la colección: "${filterTag.name}".`;
    }

    if (Array.isArray(searchResults) && searchResults.length === 0 && search) {
      return `No se encontraron productos en la búsqueda: "${search}".`;
    }

    if (search) {
      return `Productos relacionados con la búsqueda de: "${search}".`;
    }

    if (filterTag) {
      return `Productos relacionados con la colección de: "${filterTag.name}".`;
    }

    return "Descubre los productos TouBelle y lo que hacen por tu piel";
  };

  /**
   * Renderiza una lista de productos basada en los resultados de la categoría.
   * @returns {JSX.Element} Un contenedor div que contiene las tarjetas de productos.
   *
   * @description
   * Esta función recorre los resultados de la categoría y para cada producto:
   * - Verifica si el producto ya existe en la lista de todos los productos.
   * - Determina si el producto está agregado y la cantidad agregada.
   * - Verifica si el producto está fuera de stock.
   *
   * Cada tarjeta de producto incluye:
   * - Una etiqueta de promoción si el producto está en promoción.
   * - Una imagen del producto.
   * - La categoría del producto.
   * - Las características del producto.
   * - La cantidad disponible del producto.
   * - Un botón para agregar el producto al carrito, que muestra la cantidad agregada si ya está en el carrito.
   *
   * @example
   * // Uso típico de la función
   * const productList = renderProducts();
   */
  const renderProducts = () => {
    return (
      <div className='product-content'>
        <div className='product-cards'>
          {categoryResults.map((product) => {
            const existingProduct = allProducts.find(
              (item) => item.id === product.id
            );
            const isAdded = !!existingProduct;
            const addedQuantity = existingProduct?.quantity || 0;
            const isOutOfStock = product.amount <= addedQuantity;

            return (
              <div
                className='col-product-content'
                key={product.id}>
                <div className='product-card'>
                  <div className='product-card-top'>
                    {product.Promotion && (
                      <img
                        className='promotion-label'
                        src={IconProm}
                        alt='Etiqueta de Promoción.'
                      />
                    )}
                    <div className='product-image'>
                      <img
                        src={product.image}
                        alt='Imagen del Producto.'
                      />
                    </div>
                  </div>
                  <div className='product-card-bottom'>
                    <span className='category'>{product.category}</span>
                    <span className='title'>{product.name}</span>
                    <span className='weight'>
                      {parseInt(product.amount) !== 0 ? `` : "Sin stock"}
                    </span>
                    <div className='card-product-cart'>
                      <div className='card-product-buy-button'>
                        <span className='price'>
                          {moneyFormat(product.price)} COP
                        </span>
                        <button
                          className='product-add'
                          disabled={isOutOfStock}
                          onClick={() => onAddProducts(product)}>
                          <span>
                            {isAdded
                              ? `Agregado (${addedQuantity})`
                              : "Agregar"}
                          </span>
                          <i className='fa-solid fa-cart-shopping' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <section
      className='product-section'
      id='productos'
      ref={productosRef}>
      <div className='product-header'>
        <div className='product-title'>
          <h2>{getMessage()}</h2>
          {filterTag && (
            <span
              className='filter'
              onClick={() => setFilterTag("")}>
              Colección: {filterTag.name} <i className='fa-solid fa-xmark'></i>
            </span>
          )}
          {search && (
            <span
              className='filter'
              onClick={() => setSearch("")}>
              Búsqueda: {search} <i className='fa-solid fa-xmark'></i>
            </span>
          )}
        </div>
      </div>
      {renderProducts()}
    </section>
  );
};
