import React, { useContext, useState, useMemo, useCallback } from "react";
import { CartContext } from "../../contexts/ShoppingCartContext";
import { UserAccount } from "../user-account/UserAccount";
import { OrderSummary } from "../order-summary/OrderSummary";
import { useFetchSignature } from "../../hooks";
import { generateWompiUrl, preOrder, createOrden } from "../../utils";
import "./TimeLineData.css";

const FORM_ID = "order-form";

export const TimeLineData = () => {
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    shipmentData,
    dataRecord,
    total,
    setTotal,
    shipping,
    allProducts,
    setAllProducts,
    deliveryData,
    thirdPersonData,
    setCountProducts,
  } = useContext(CartContext);

  /**
   * Vacía el carrito de compras. Esta función utiliza `useCallback` para memorizar la función y evitar recrearla en cada renderizado. Al ejecutarse, vacía la lista de productos,
   * establece el total a 0 y el contador de productos a 0.
   * @function
   * @name emptyCart
   * @returns {void}
   * @example
   * emptyCart();
   * console.log(allProducts);
   * // []
   */
  const emptyCart = useCallback(() => {
    setAllProducts([]);
    setTotal(0);
    setCountProducts(0);
  }, [setAllProducts, setTotal, setCountProducts]);

  const redirectToHome = () => {
    window.location.href = "/";
  };

  /**
   * Calcula los detalles del pago.
   * @returns {Object} Un objeto que contiene los detalles del pago.
   * @returns {number} return.amount - La cantidad total a pagar, que es la suma de `total` y `shipping`.
   * @returns {string} return.currency - La moneda en la que se realizará el pago, en este caso "COP".
   * @example
   * const paymentDetails = useMemo(() => calculatePaymentDetails(total, shipping), [total, shipping]);
   * console.log(paymentDetails);
   * // { amount: 10000, currency: "COP" }
   */
  const paymentDetails = useMemo(() => {
    return {
      amount: total + shipping,
      currency: "COP",
    };
  }, [shipping, total]);

  const { data: signatureResponse } = useFetchSignature(paymentDetails);

  /**
   * Maneja el evento de envío del formulario de pago.
   * @param {Event} e - El evento de envío del formulario.
   * @returns {Promise<void>} - Una promesa que se resuelve cuando el proceso de pago se completa.
   * @async
   * @throws {Error} - Si ocurre un error al generar la orden.
   * @example
   * handleOnPaymentSubmit(event);
   * console.log("Orden generada con éxito");
   * // "Orden generada con éxito"
   */
  const handleOnPaymentSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const validation = await preOrder({
        products: allProducts,
        paymentDetails,
        signatureResponse,
        shipping,
        shipmentData,
        deliveryData,
        thirdPersonData,
      });

      if (!validation) {
        setMessage(
          "No hay productos disponibles para completar la orden. Por favor, revisa tu pedido e inténtalo nuevamente."
        );
        return;
      } else {
        setMessage("");
      }

      if (deliveryData.Forma_Pago === "digital") {
        window.location.href = generateWompiUrl(
          paymentDetails,
          signatureResponse
        );

        // window.open(
        //   generateWompiUrl(paymentDetails, signatureResponse),
        //   "_blank"
        // );
      } else {
        const response = await createOrden({
          event: "pago contra entrega",
          data: {
            transaction: {
              reference: signatureResponse.reference,
            },
          },
        });

        console.log(response);

        if (response.status === 200) {
          redirectToHome();
          emptyCart();
        }
      }
    } catch (error) {
      console.error("Error al generar orden", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {(dataRecord || shipmentData) && (
        <div className='data-user-container'>
          <h1>Ingresa los datos</h1>
          <div className='data-user-cart-content'>
            <div className='data-user-content'>
              <UserAccount
                onSubmit={handleOnPaymentSubmit}
                isSubmitting={isLoading}
                formId={FORM_ID}
              />
            </div>
            <div className='data-order-summary'>
              <OrderSummary
                isSubmitting={isLoading}
                formId={FORM_ID}
                message={message}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
